<template>
  <b-overlay
      :show="[FORM_STATUS.INITIALIZING, FORM_STATUS.LOADING].includes(formStatus)"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
    <b-card title="Добавление позиции">
      <div class="position-settings-wrapper">
        <validation-observer ref="positionSettingsValidation">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Название *"
                  label-for="position-settings-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Название"
                    rules="required"
                  >
                    <b-form-input
                      id="position-settings-name"
                      v-model="formData.name"
                      :state="errors.length > 0 ? false : null"
                      name="position-settings-name"
                      placeholder="Бургер"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Категория *"
                  label-for="position-settings-category"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Категория"
                    rules="required"
                  >
                    <b-form-select
                      id="position-settings-category"
                      v-model="formData.category_id"
                      name="position-settings-category"
                      :state="errors.length > 0 ? false : null"
                      :options="categories"
                      placeholder="Выберите категорию"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Изображение"
                  label-for="position-settings-image"
                  class="image-uploader"
                >
                  <b-img
                    v-if="imageUrl"
                    class="image-uploader-thumbnail"
                    thumbnail
                    fluid
                    :src="imageUrl"
                    width="200"
                    height="200"
                  />
                  <div class="image-uploader-controllers">
                    <b-form-file
                      id="position-settings-image"
                      v-model="formData.image"
                      placeholder="Выберите файл или скиньте его сюда..."
                      drop-placeholder="Скиньте файл сюда..."
                      name="position-settings-image"
                      browse-text="Изменить"
                    />
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Цена *"
                  label-for="position-settings-amount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Цена"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="position-settings-amount"
                      type="number"
                      v-model="formData.amount"
                      :state="errors.length > 0 ? false : null"
                      name="position-settings-amount"
                      placeholder="500"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Описание блюда"
                  label-for="position-settings-description"
                >
                  <b-form-textarea
                    id="position-settings-description"
                    v-model="formData.description"
                    placeholder="Кетчуп и майонез, долька кабачка, листья салата"
                    rows="3"
                    name="position-settings-description"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Порядковый номер"
                  label-for="position-settings-position"
                >
                  <b-form-input
                    id="position-settings-position"
                    type="number"
                    v-model="formData.position"
                    name="position-settings-position"
                    placeholder="1"
                  />
                  <b-form-text>Укажите номер, который задаст порядок отображения позиции в электронном меню</b-form-text>
                </b-form-group>
              </b-col>

              <b-col class="mb-1" cols="12">
                <b-form-checkbox
                  v-model="formData.is_active"
                  :checked="formData.is_active"
                  name="check-button"
                  switch
                  inline
                >
                  Активно
                </b-form-checkbox>
              </b-col>

              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click="submitFormData"
                >
                  Сохранить
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BButton, BRow, BCol, BFormGroup, BFormText, BFormInput, BForm, BOverlay, BFormTextarea, BFormCheckbox, BFormSelect, BFormFile, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, numeric } from '@validations'
import { getApiErrMsg } from '@core/utils/utils'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const FORM_STATUS = {
  INITIAL: 1,
  INITIALIZING: 2,
  LOADING: 3,
  SUCCESS: 4,
  ERROR: 5,
}

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormText,
    BFormInput,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BFormSelect,
    BFormFile,
    BImg,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    Ripple
  },
  data() {
    return {
      required,
      integer,
      numeric,
      FORM_STATUS,
      formStatus: FORM_STATUS.INITIAL,
      formData: {
        name: '',
        category: null,
        image: null,
        amount: null,
        description: '',
        position: null,
        is_active: false,
      },
      categories: [],
    }
  },
  computed: {
    imageUrl() {
      return this.formData.image ? URL.createObjectURL(this.formData.image) : ''
    },
  },
  methods: {
    async fetchCategories() {
      try {
        const res = await useJwt.getCategories()

        this.categories = [
          ...[{ value: null, text: 'Выберите категорию' }],
          ...res.data.map((category) => ({ value: category.id, text: category.name })),
        ]
      } catch(err) {}
    },
    getFormData() {
      const data = new FormData()
      const fields = {
        name: 'name',
        category_id: 'category_id',
        position: 'position',
        image: 'image',
        amount: 'amount',
        description: 'description',
        position: 'position',
        is_active: 'is_active',
      }

      for (const field in fields) {
        let fieldValue = this.formData[fields[field]]

        if (field === 'position' && isNaN(parseInt(fieldValue))) {
          fieldValue = 999
        } else if (field === 'image' && fieldValue === null) {
          fieldValue = ''
        }

        data.append(field, fieldValue)
      }

      return data
    },
    async submitFormData() {
      const validationRes = await this.$refs.positionSettingsValidation.validate()

      if (!validationRes) {
        return
      }

      this.formStatus = FORM_STATUS.LOADING

      try {
        await useJwt.createPosition(this.getFormData(), {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.formStatus = FORM_STATUS.INITIAL

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Позиция добавлена.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })

        this.$router.push({ name: 'positions' })
      } catch(err) {
        this.formStatus = FORM_STATUS.INITIAL

        const msg = getApiErrMsg(err, 'Не удалось добавить позиции, попробуйте еще раз.')

        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  created() {
    this.fetchCategories()
  }
}
</script>

<style>
.position-settings-wrapper {
  max-width: 500px;
}

.image-uploader-thumbnail {
  margin-bottom: 1rem;
  max-height: none;
  height: 200px;
  width: 200px;
  object-fit: contain;
  object-position: center;
}

.image-uploader-controllers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.image-uploader .custom-file {
  width: 200px;
}

.image-uploader .custom-file-label {
  padding: 0;
  position: static;
  width: 100%;
}

.image-uploader .custom-file-label::after {
  position: static;
  border-left: none;
  padding: 0.786rem 1.5rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  cursor: pointer;
}

.image-uploader .custom-file-input {
  position: absolute;
  top: 0;
  left: 0;
}

.image-uploader .form-file-text {
  display: none !important;
}
</style>
